import { ActionTypes } from "./constants";

import {
    authApiResponseSuccess
} from "./actions"

import { all, fork, takeEvery, put } from 'redux-saga/effects';

function* updateInstanceDetail({ payload: { data } }) {
    yield put(authApiResponseSuccess(ActionTypes.UPDATE_INSTANCE_DETAIL, data));
}

export function* watchUpdateInstanceDetail() {
    yield takeEvery(ActionTypes.UPDATE_INSTANCE_DETAIL, updateInstanceDetail);
}

function* AuthSaga() {
    yield all([
        fork(watchUpdateInstanceDetail)
    ]);
}

export default AuthSaga;
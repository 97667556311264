export const VolumeDiscountActionTypes = {
    CREATE_VOLUME_DISCOUNT: "CREATE_VOLUME_DISCOUNT",
    UPDATE_VOLUME_DISCOUNT: "UPDATE_VOLUME_DISCOUNT",
    VOLUME_DISCOUNT_LIST: "VOLUME_DISCOUNT_LIST",
    GET_VOLUME_DISCOUNT: "GET_VOLUME_DISCOUNT",
    DELETE_VOLUME_DISCOUNT: "DELETE_VOLUME_DISCOUNT",

    API_RESPONSE_SUCCESS: 'API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: 'API_RESPONSE_ERROR',

}

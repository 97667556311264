import {
    VolumeDiscountActionTypes
} from "./constants";

export const listVolumeDiscounts = (data, instance_id) => ({
    type: VolumeDiscountActionTypes.VOLUME_DISCOUNT_LIST,
    payload: { data, instance_id },
});


export const getVolumeDiscount = (data, instance_id) => ({
    type: VolumeDiscountActionTypes.GET_VOLUME_DISCOUNT,
    payload: { data, instance_id },
});

export const createVolumeDiscount = (data, instance_id) => ({
    type: VolumeDiscountActionTypes.CREATE_VOLUME_DISCOUNT,
    payload: { data, instance_id },
});

export const updateVolumeDiscount = (data, id, instance_id) => ({
    type: VolumeDiscountActionTypes.UPDATE_VOLUME_DISCOUNT,
    payload: { data, id, instance_id },
});

export const deleteVolumeDiscount = (data, instance_id) => ({
    type: VolumeDiscountActionTypes.DELETE_VOLUME_DISCOUNT,
    payload: { data, instance_id },
});

export const authApiResponseSuccess = (actionType, data) => ({
    type: VolumeDiscountActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const authApiResponseError = (actionType, error) => ({
    type: VolumeDiscountActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});
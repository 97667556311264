import Dashboard from "./components/Dashboard";
import Home from "./components/Home";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Bundle from "./components/bundle/Bundle";
import ProductMapping from "./components/product_mapping/ProductMapping";
import VolumeDiscount from "./components/volume_discount/VolumeDiscount";
import VolumeDiscountCreate from "./components/volume_discount/VolumeDiscountCreate";
import VolumeDiscountEdit from "./components/volume_discount/VolumeDiscountEdit";
import ProductMappingCreate from "./components/product_mapping/ProductMappingCreate";
import ProductMappingEdit from "./components/product_mapping/ProductMappingEdit";
import BundleCreate from "./components/bundle/BundleCreate";
import BundleEdit from "./components/bundle/BundleEdit";

function App() {


    return (
        <div className="App">
            <BrowserRouter>
                <Routes>
                    <Route>
                        <Route path="/bundles" element={<Bundle />} />
                        <Route path="/bundles/new" element={<BundleCreate />} />
                        <Route path="/bundles/:id/edit" element={<BundleEdit />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/" element={<Home />} />

                        <Route path="/cross_sell" element={<ProductMapping />} />
                        <Route path="/cross_sell/new" element={<ProductMappingCreate />} />
                        <Route path="/cross_sell/:id/edit" element={<ProductMappingEdit />} />

                        <Route path="/volume_discounts" element={<VolumeDiscount />} />
                        <Route path="/volume_discounts/new" element={<VolumeDiscountCreate />} />
                        <Route path="/volume_discounts/:id/edit" element={<VolumeDiscountEdit />} />

                    </Route>
                </Routes>
            </BrowserRouter>
        </div>
    );

}

export default App;

import AuthReducer from './auth/reducers'
import BundleReducer from './bundle/reducers'
import ProductMappingReducer from "./product_mapping/reducers";
import VolumeDiscountReducer from "./volume_discount/reducers";
import { combineReducers } from 'redux';

const rootReducer = combineReducers({
    AuthReducer, BundleReducer, ProductMappingReducer, VolumeDiscountReducer
});

export default rootReducer;
import {ProductMappingActionTypes} from "./constants";

const initial_state = {
    product_mappings: [],
    product_mapping: ""
};

const ProductMappingReducer = (state = initial_state, action) => {

    switch (action.type) {
        case ProductMappingActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case ProductMappingActionTypes.PRODUCT_MAPPING_LIST: {
                    return {
                        ...state,
                        product_mappings: action.payload.data,
                        loading: false,
                    };
                }
                case ProductMappingActionTypes.GET_PRODUCT_MAPPING: {
                    return {
                        ...state,
                        product_mapping: action.payload.data,
                        loading: false,
                    };
                }
                case ProductMappingActionTypes.CREATE_PRODUCT_MAPPING: {
                    let product_mappings = action.payload.product_mappings
                    product_mappings.push(action.payload.product_mapping)
                    return {
                        ...state,
                        product_mappings: product_mappings,
                        loading: false
                    };
                }
                case ProductMappingActionTypes.UPDATE_PRODUCT_MAPPING: {

                    let product_mappings = action.payload.data.product_mappings.filter(product_mapping => product_mapping.id !== action.payload.data.id)
                    product_mappings.push(action.payload.data.product_mapping)

                    return {
                        ...state,
                        product_mappings: product_mappings,
                        loading: false
                    };
                }
                case ProductMappingActionTypes.DELETE_PRODUCT_MAPPING: {
                    return {
                        ...state,
                        product_mappings: action.payload.data.product_mappings.filter(product_mapping => product_mapping.id !== action.payload.data.id),
                        loading: false
                    };
                }
                default:
                    return {...state};
            }


        case ProductMappingActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case ProductMappingActionTypes.PRODUCT_MAPPING_LIST: {
                    return {
                        ...state,
                        product_mappings: [],
                        loading: false,
                    };
                }
                case ProductMappingActionTypes.GET_PRODUCT_MAPPING: {
                    return {
                        ...state,
                        product_mapping: "",
                        loading: false,
                    };
                }
                case ProductMappingActionTypes.CREATE_PRODUCT_MAPPING: {
                    return {
                        ...state,
                        product_mapping: action.payload.data,
                        loading: false
                    };
                }
                default:
                    return {...state};
            }
        default:
            return {...state};
    }
}

export default ProductMappingReducer;
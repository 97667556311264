import { all, fork, takeEvery, put, call } from 'redux-saga/effects';
import { select } from 'redux-saga/effects'

import {
    productMappingCreate as productMappingCreateApi,
    productMappingList as productMappingListApi,
    productMappingDelete as productMappingDeleteApi,
    productMappingUpdate as productMappingUpdateApi,
    productMappingGet as productMappingGetApi
} from "../../helpers/api/product_mapping"

import { ProductMappingActionTypes } from "./constants";
import {authApiResponseError, authApiResponseSuccess} from "./actions";


function* create({ payload: { data, instance_id } }) {

    const items = yield select();

    try {

        const response = yield call(productMappingCreateApi, {data: data, instance_id: instance_id });
        const productMapping = response.data.product_mapping;

        yield put(authApiResponseSuccess(ProductMappingActionTypes.CREATE_PRODUCT_MAPPING, {product_mappings: items.ProductMappingReducer.product_mappings, product_mapping: productMapping }));
    } catch (error) {
        yield put(authApiResponseError(ProductMappingActionTypes.CREATE_PRODUCT_MAPPING, error));
    }
}

function* list(action) {

    try {
        const response = yield call(productMappingListApi, {instance_id: action.payload.instance_id});
        const productMappings = response.data?.product_mappings;

        yield put(authApiResponseSuccess(ProductMappingActionTypes.PRODUCT_MAPPING_LIST, productMappings));
    } catch (error) {
        yield put(authApiResponseError(ProductMappingActionTypes.PRODUCT_MAPPING_LIST, error));
    }
}

function* get(action) {

    try {
        const response = yield call(productMappingGetApi, {id: action.payload.data, instance_id: action.payload.instance_id});
        const productMapping = response.data?.product_mapping;

        yield put(authApiResponseSuccess(ProductMappingActionTypes.GET_PRODUCT_MAPPING, productMapping));
    } catch (error) {
        yield put(authApiResponseError(ProductMappingActionTypes.GET_PRODUCT_MAPPING, error));
    }
}

function* update(action) {

    const items = yield select();

    try {
        const response = yield call(productMappingUpdateApi, {id: action.payload.id, data: action.payload.data, instance_id: action.payload.instance_id});
        const productMapping = response.data?.product_mapping;

        yield put(authApiResponseSuccess(ProductMappingActionTypes.UPDATE_PRODUCT_MAPPING, {product_mappings: items.ProductMappingReducer.product_mappings, product_mapping: productMapping, id: action.payload.data }));
    } catch (error) {
        yield put(authApiResponseError(ProductMappingActionTypes.UPDATE_PRODUCT_MAPPING, error));
    }
}

function* deleteProductMapping(action) {

    const items = yield select();

    try {

        yield call(productMappingDeleteApi, {id: action.payload.data, instance_id: action.payload.instance_id});

        yield put(authApiResponseSuccess(ProductMappingActionTypes.DELETE_PRODUCT_MAPPING, {product_mappings: items.ProductMappingReducer.product_mappings, id: action.payload.data }));
    } catch (error) {
        yield put(authApiResponseError(ProductMappingActionTypes.DELETE_PRODUCT_MAPPING, error));
    }
}

export function* watchProductMappingCreate() {
    yield takeEvery(ProductMappingActionTypes.CREATE_PRODUCT_MAPPING, create);
}

export function* watchProductMappingList() {
    yield takeEvery(ProductMappingActionTypes.PRODUCT_MAPPING_LIST, list);
}

export function* watchProductMappingDelete() {
    yield takeEvery(ProductMappingActionTypes.DELETE_PRODUCT_MAPPING, deleteProductMapping);
}

export function* watchProductMappingGet() {
    yield takeEvery(ProductMappingActionTypes.GET_PRODUCT_MAPPING, get);
}

export function* watchProductMappingUpdate() {
    yield takeEvery(ProductMappingActionTypes.UPDATE_PRODUCT_MAPPING, update);
}


function* productMappingSaga() {
    yield all([
        fork(watchProductMappingCreate),
        fork(watchProductMappingList),
        fork(watchProductMappingDelete),
        fork(watchProductMappingGet),
        fork(watchProductMappingUpdate)
    ]);
}

export default productMappingSaga;
import {
    ProductMappingActionTypes
} from "./constants";

export const listProductMappings = (data, instance_id) => ({
    type: ProductMappingActionTypes.PRODUCT_MAPPING_LIST,
    payload: { data, instance_id },
});


export const getProductMapping = (data, instance_id) => ({
    type: ProductMappingActionTypes.GET_PRODUCT_MAPPING,
    payload: { data, instance_id },
});

export const createProductMapping = (data, instance_id) => ({
    type: ProductMappingActionTypes.CREATE_PRODUCT_MAPPING,
    payload: { data, instance_id },
});

export const updateProductMapping = (data, id, instance_id) => ({
    type: ProductMappingActionTypes.UPDATE_PRODUCT_MAPPING,
    payload: { data, id, instance_id },
});

export const deleteProductMapping = (data, instance_id) => ({
    type: ProductMappingActionTypes.DELETE_PRODUCT_MAPPING,
    payload: { data, instance_id },
});

export const authApiResponseSuccess = (actionType, data) => ({
    type: ProductMappingActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const authApiResponseError = (actionType, error) => ({
    type: ProductMappingActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});
import { APICore } from './apiCore';

const api = new APICore();

function bundleCreate(requestBody) {
    const baseUrl = '/bundles?instance=' + requestBody.instance_id ;
    return api.create(`${baseUrl}`, requestBody.data, { headers: { 'Content-Type': 'application/json', Accept: "*/*" }});
}

function bundleList(requestBody) {
    const baseUrl = '/bundles?instance=' + requestBody.instance_id ;
    return api.get(`${baseUrl}`, { headers: { 'Content-Type': 'application/json', Accept: "*/*" }});
}

function productList(requestBody) {
    const baseUrl = '/products?instance=' + requestBody.instance_id ;
    return api.get(`${baseUrl}`, { headers: { 'Content-Type': 'application/json', Accept: "*/*" }});
}

function bundleDelete(requestBody) {
    const baseUrl = '/bundles/' + requestBody.id +'?instance=' + requestBody.instance_id ;
    return api.delete(`${baseUrl}`, { headers: { 'Content-Type': 'application/json', Accept: "*/*" }});
}

function bundleGet(requestBody) {
    const baseUrl = '/bundles/' + requestBody.id +'?instance=' + requestBody.instance_id ;
    return api.get(`${baseUrl}`, { headers: { 'Content-Type': 'application/json', Accept: "*/*" }});
}

function bundleUpdate(requestBody) {
    const baseUrl = '/bundles/' + requestBody.id +'?instance=' + requestBody.instance_id ;
    return api.update(`${baseUrl}`, requestBody.data, { headers: { 'Content-Type': 'application/json', Accept: "*/*" }});
}


export { bundleCreate, bundleList, bundleDelete, bundleGet, bundleUpdate, productList };
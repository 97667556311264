import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Container, ModalHeader, ModalBody, ModalFooter, Modal
} from 'reactstrap';
import {updateProductMapping, getProductMapping, listProductMappings} from '../../redux/product_mapping/actions';
import {useParams, useNavigate} from "react-router-dom";
import {listBundleProducts} from "../../redux/bundle/actions";

const ProductMappingEdit = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const params = useParams();
    const [productMappingObject, setProductMappingObject] = useState('');
    const queryParams = new URLSearchParams(window.location.search);
    const instanceId = queryParams.get('instanceId');
    const [modal, setModal] = useState(false);
    const [productType, setProductType] = useState("source_product");
    const [filteredSourceProducts, setFilteredSourceProducts] = useState([]);
    const [selectedSourceProducts, setSelectedSourceProducts] = useState([]);
    const [filteredDestinationProducts, setFilteredDestinationProducts] = useState([]);
    const [selectedDestinationProducts, setSelectedDestinationProducts] = useState([]);

    const toggle = (value) => {
        setProductType(value)
        setModal(!modal);
    }


    useEffect(() => {
        dispatch(listProductMappings("", instanceId));
        dispatch(getProductMapping(params.id, instanceId));
        dispatch(listBundleProducts(instanceId))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {product_mapping, products} = useSelector((state) => ({
        product_mapping: state.ProductMappingReducer.product_mapping,
        products: state.BundleReducer.bundle_products
    }));

    useEffect(() => {
        setProductMappingObject(product_mapping)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [product_mapping])

    useEffect(() => {
        let selectedSourceProductList = []
        let selectedDestinationProductList = []

        console.log(product_mapping)

        for (let i = 0; i < products.length; i++) {
            if (product_mapping.source_product_ids) {
                if (product_mapping.source_product_ids.includes(`${products[i].id}`)) {
                    selectedSourceProductList.push(products[i])
                }
            }

            if (product_mapping.destination_product_ids) {
                if (product_mapping.destination_product_ids.includes(`${products[i].id}`)) {
                    selectedDestinationProductList.push(products[i])
                }
            }
        }

        setSelectedSourceProducts(selectedSourceProductList)
        setSelectedDestinationProducts(selectedDestinationProductList)
        // eslint-disable-next-line
    }, [products])


    const handleRemoveSelectedProducts = (product, productTyp) => {
        let selectedProductList = null
        if(productTyp === "source_product"){
            selectedProductList = selectedSourceProducts
            setSelectedSourceProducts(selectedProductList.filter(p => p.id !== product.id))

            let products_ids = productMappingObject.source_product_ids
            products_ids = products_ids.filter(p => p.toString() !== product.id.toString())

            setProductMappingObject({
                ...productMappingObject, source_product_ids: products_ids
            })
        }
        else{
            selectedProductList = selectedDestinationProducts
            setSelectedDestinationProducts(selectedProductList.filter(p => p.id !== product.id))

            let products_ids = productMappingObject.destination_product_ids
            products_ids = products_ids.filter(p => p.toString() !== product.id.toString())

            setProductMappingObject({
                ...productMappingObject, destination_product_ids: products_ids
            })
        }
    }

    const handleSetSearchedProducts = (product) => {
        let products_ids = null;

        if(productType === "source_product"){

            products_ids = productMappingObject.source_product_ids
            products_ids.push(product.id)

            let selectedProductList = selectedSourceProducts
            selectedProductList.push(product)

            setSelectedSourceProducts(selectedProductList)

            setProductMappingObject({
                ...productMappingObject, source_product_ids: products_ids
            })
            setModal(false)
            setFilteredSourceProducts([])

        }
        else{
            products_ids = productMappingObject.destination_product_ids
            products_ids.push(product.id)

            let selectedProductList = selectedDestinationProducts
            selectedProductList.push(product)

            setSelectedDestinationProducts(selectedProductList)

            setProductMappingObject({
                ...productMappingObject, destination_product_ids: products_ids
            })
            setModal(false)
            setFilteredDestinationProducts([])
        }

    }

    const handleSetProducts = (value) => {
        const filteredProductsList = products.filter(product => product.name.includes(value))

        let productsList = []
        for (let i = 0; i < filteredProductsList.length; i++) {
            if(productType === "source_product"){
                if (!productMappingObject.source_product_ids.includes(filteredProductsList[i].id)) {
                    productsList.push(filteredProductsList[i])
                }
            }
            else{
                if (!productMappingObject.destination_product_ids.includes(filteredProductsList[i].id)) {
                    productsList.push(filteredProductsList[i])
                }
            }
        }

        if(productType === "source_product"){
            setFilteredSourceProducts(productsList)
        }
        else{
            setFilteredDestinationProducts(productsList)
        }

    }

    const handleSubmit = (e) => {
        e.preventDefault();

        const requestBody = {product_mapping: productMappingObject}

        dispatch(updateProductMapping(requestBody, params.id, instanceId))

        history("/cross_sell?instanceId=" + instanceId)

    };


    return (
        <Container className="mt-3">
            <h3 className="text-center mb-3">Edit Cross sell offer</h3>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label for="productMappingName">Name</Label>
                    <Input type="text"
                           name="name"
                           id="productMappingName"
                           value={productMappingObject.name}
                           onChange={event => setProductMappingObject({
                               ...productMappingObject, name: event.target.value
                           })}
                           placeholder="ProductMapping name"/>
                </FormGroup>

                <FormGroup check className="mb-2">
                    <Input
                        checked={productMappingObject.on_product_page}
                        onChange={event => setProductMappingObject({
                            ...productMappingObject, on_product_page: event.target.checked
                        })}
                        type="checkbox"
                    />
                    {' '}
                    <Label check>
                        On Product Page
                    </Label>
                </FormGroup>


                <FormGroup check className="mb-2">
                    <Input
                        onChange={event => setProductMappingObject({
                            ...productMappingObject, on_cart_page: event.target.checked
                        })}
                        checked={productMappingObject.on_cart_page}
                        type="checkbox"
                    />
                    {' '}
                    <Label check>
                        On Cart Page
                    </Label>
                </FormGroup>


                <FormGroup check className="mb-2">
                    <Input
                        onChange={event => setProductMappingObject({
                            ...productMappingObject, only_above_price: event.target.checked
                        })}
                        checked={productMappingObject.only_above_price}
                        type="checkbox"
                    />
                    {' '}
                    <Label check>
                        On Above Price
                    </Label>
                </FormGroup>

                <FormGroup check className="mb-2">
                    <Input
                        onChange={event => setProductMappingObject({
                            ...productMappingObject, only_below_price: event.target.checked
                        })}
                        checked={productMappingObject.only_below_price}
                        type="checkbox"
                    />
                    {' '}
                    <Label check>
                        On Below Price
                    </Label>
                </FormGroup>

                <FormGroup check className="mb-2">
                    <Input
                        onChange={event => setProductMappingObject({
                            ...productMappingObject, only_above_quantity: event.target.checked
                        })}
                        checked={productMappingObject.only_above_quantity}
                        type="checkbox"
                    />
                    {' '}
                    <Label check>
                        On Above Quantity
                    </Label>
                </FormGroup>

                <FormGroup check className="mb-2">
                    <Input
                        onChange={event => setProductMappingObject({
                            ...productMappingObject, only_below_quantity: event.target.checked
                        })}
                        checked={productMappingObject.only_below_quantity}
                        type="checkbox"
                    />
                    {' '}
                    <Label check>
                        On Below Quantity
                    </Label>
                </FormGroup>

                <FormGroup>
                    <button type="button" className="btn btn-outline-primary" onClick={event => { toggle("source_product") }}>
                        Select Source Products
                    </button>

                    <div className="mt-3">
                        {selectedSourceProducts.map(product => <div className="mt-2" key={product.id}><span><img
                            src={product.wix_image_id} style={{width: "100px"}} alt=""/>{product.name}</span>
                            <button className="btn btn-sm btn-outline-danger" type="button"
                                    onClick={event => handleRemoveSelectedProducts(product, "source_product")}
                                    style={{marginLeft: "50px"}}>Remove
                            </button>
                        </div>)}
                    </div>
                </FormGroup>

                <FormGroup>
                    <button type="button" className="btn btn-outline-primary" onClick={event => { toggle("destination_product") }}>
                        Select Destination Products
                    </button>

                    <div className="mt-3">
                        {selectedDestinationProducts.map(product => <div className="mt-2" key={product.id}><span><img
                            src={product.wix_image_id} style={{width: "100px"}} alt=""/>{product.name}</span>
                            <button className="btn btn-sm btn-outline-danger" type="button"
                                    onClick={event => handleRemoveSelectedProducts(product, "destination_product")}
                                    style={{marginLeft: "50px"}}>Remove
                            </button>
                        </div>)}
                    </div>
                </FormGroup>



                <Button>Update</Button>
            </Form>


            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Select Products</ModalHeader>
                <ModalBody>

                    <FormGroup>
                        <Label>Search</Label>
                        <input type="text"
                               onChange={event => handleSetProducts(event.target.value)}
                               placeholder="Search Phrase, e.g. Shirts for men"
                               className="form-control"/>
                    </FormGroup>

                    <div className="mt-3">
                        {   productType === "source_product" ?
                            filteredSourceProducts.map(product => <div className="mt-2" style={{cursor: "pointer"}}
                                                                       key={product.id}
                                                                       onClick={event => handleSetSearchedProducts(product)}><img
                                src={product.wix_image_id} style={{width: "100px"}} alt=""/>{product.name}</div>)
                            :

                            filteredDestinationProducts.map(product => <div className="mt-2" style={{cursor: "pointer"}}
                                                                            key={product.id}
                                                                            onClick={event => handleSetSearchedProducts(product)}><img
                                src={product.wix_image_id} style={{width: "100px"}} alt=""/>{product.name}</div>)
                        }
                    </div>

                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>


        </Container>
    );
}

export default ProductMappingEdit;
import {VolumeDiscountActionTypes} from "./constants";

const initial_state = {
    volume_discounts: [],
    volume_discount: ""
};

const VolumeDiscountReducer = (state = initial_state, action) => {

    switch (action.type) {
        case VolumeDiscountActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case VolumeDiscountActionTypes.VOLUME_DISCOUNT_LIST: {
                    return {
                        ...state,
                        volume_discounts: action.payload.data,
                        loading: false,
                    };
                }
                case VolumeDiscountActionTypes.GET_VOLUME_DISCOUNT: {
                    return {
                        ...state,
                        volume_discount: action.payload.data,
                        loading: false,
                    };
                }
                case VolumeDiscountActionTypes.CREATE_VOLUME_DISCOUNT: {
                    let volume_discounts = action.payload.volume_discounts
                    volume_discounts.push(action.payload.volume_discount)
                    return {
                        ...state,
                        volume_discounts: volume_discounts,
                        loading: false
                    };
                }
                case VolumeDiscountActionTypes.UPDATE_VOLUME_DISCOUNT: {

                    let volume_discounts = action.payload.data.volume_discounts.filter(volume_discount => volume_discount.id !== action.payload.data.id)
                    volume_discounts.push(action.payload.data.volume_discount)

                    return {
                        ...state,
                        volume_discounts: volume_discounts,
                        loading: false
                    };
                }
                case VolumeDiscountActionTypes.DELETE_VOLUME_DISCOUNT: {
                    return {
                        ...state,
                        volume_discounts: action.payload.data.volume_discounts.filter(volume_discount => volume_discount.id !== action.payload.data.id),
                        loading: false
                    };
                }
                default:
                    return {...state};
            }


        case VolumeDiscountActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case VolumeDiscountActionTypes.VOLUME_DISCOUNT_LIST: {
                    return {
                        ...state,
                        volume_discounts: [],
                        loading: false,
                    };
                }
                case VolumeDiscountActionTypes.GET_VOLUME_DISCOUNT: {
                    return {
                        ...state,
                        volume_discount: "",
                        loading: false,
                    };
                }
                case VolumeDiscountActionTypes.CREATE_VOLUME_DISCOUNT: {
                    return {
                        ...state,
                        volume_discount: action.payload.data,
                        loading: false
                    };
                }
                default:
                    return {...state};
            }
        default:
            return {...state};
    }
}

export default VolumeDiscountReducer;
import { APICore } from './apiCore';

const api = new APICore();

function volumeDiscountCreate(requestBody) {
    const baseUrl = '/volume_discounts?instance=' + requestBody.instance_id ;
    return api.create(`${baseUrl}`, requestBody.data, { headers: { 'Content-Type': 'application/json', Accept: "*/*" }});
}

function volumeDiscountList(requestBody) {
    const baseUrl = '/volume_discounts?instance=' + requestBody.instance_id ;
    return api.get(`${baseUrl}`, { headers: { 'Content-Type': 'application/json', Accept: "*/*" }});
}

function volumeDiscountDelete(requestBody) {
    const baseUrl = '/volume_discounts/' + requestBody.id +'?instance=' + requestBody.instance_id ;
    return api.delete(`${baseUrl}`, { headers: { 'Content-Type': 'application/json', Accept: "*/*" }});
}

function volumeDiscountGet(requestBody) {
    const baseUrl = '/volume_discounts/' + requestBody.id +'?instance=' + requestBody.instance_id ;
    return api.get(`${baseUrl}`, { headers: { 'Content-Type': 'application/json', Accept: "*/*" }});
}

function volumeDiscountUpdate(requestBody) {
    const baseUrl = '/volume_discounts/' + requestBody.id +'?instance=' + requestBody.instance_id ;
    return api.update(`${baseUrl}`, requestBody.data, { headers: { 'Content-Type': 'application/json', Accept: "*/*" }});
}


export { volumeDiscountCreate, volumeDiscountList, volumeDiscountDelete, volumeDiscountGet, volumeDiscountUpdate };
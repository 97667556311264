import { APICore } from './apiCore';

const api = new APICore();

function productMappingCreate(requestBody) {
    const baseUrl = '/products_mappings?instance=' + requestBody.instance_id ;
    return api.create(`${baseUrl}`, requestBody.data, { headers: { 'Content-Type': 'application/json', Accept: "*/*" }});
}

function productMappingList(requestBody) {
    const baseUrl = '/products_mappings?instance=' + requestBody.instance_id ;
    return api.get(`${baseUrl}`, { headers: { 'Content-Type': 'application/json', Accept: "*/*" }});
}

function productMappingDelete(requestBody) {
    const baseUrl = '/products_mappings/' + requestBody.id +'?instance=' + requestBody.instance_id ;
    return api.delete(`${baseUrl}`, { headers: { 'Content-Type': 'application/json', Accept: "*/*" }});
}

function productMappingGet(requestBody) {
    const baseUrl = '/products_mappings/' + requestBody.id +'?instance=' + requestBody.instance_id ;
    return api.get(`${baseUrl}`, { headers: { 'Content-Type': 'application/json', Accept: "*/*" }});
}

function productMappingUpdate(requestBody) {
    const baseUrl = '/products_mappings/' + requestBody.id +'?instance=' + requestBody.instance_id ;
    return api.update(`${baseUrl}`, requestBody.data, { headers: { 'Content-Type': 'application/json', Accept: "*/*" }});
}


export { productMappingCreate, productMappingList, productMappingDelete, productMappingGet, productMappingUpdate };
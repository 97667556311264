import React, {useEffect} from "react";
import {Table, Container} from "reactstrap";
import {useDispatch, useSelector} from 'react-redux';
import { listBundles, deleteBundle } from '../../redux/bundle/actions';

const Bundle = () => {
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(window.location.search);
    const instanceId = queryParams.get('instanceId');


    useEffect(() => {
        dispatch(listBundles("", instanceId));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { bundles } = useSelector((state) => ({
      bundles: state.BundleReducer.bundles
    }));

    const deleteBundleObject = (id) => {
        if (window.confirm("Are you sure?")) {
          dispatch(deleteBundle(id, instanceId))
        }
    }

    return (
        <Container className="mt-3">
            <h3 className="mt-3 mb-3">Bundles</h3>
            <a href={`/bundles/new?instanceId=${instanceId}`} className="btn btn-outline-primary mb-3 float-end">New Bundle</a>
            <Table bordered>
                <thead>
                <tr>
                    <th>
                       Name
                    </th>
                    <th>
                        Type
                    </th>
                    <th>
                       Off
                    </th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                { bundles.map( bundle =>
                <tr key={bundle.id}>
                    <th scope="row">
                        {bundle.name}
                    </th>
                    <td>
                        {bundle.bundle_type}
                    </td>
                    <td>
                        {bundle.amount}
                    </td>
                    <td>
                        <a href={`/bundles/${bundle.id}/edit?instanceId=${instanceId}`} className="btn btn-sm btn-outline-secondary">Edit</a>
                        <button onClick={() => deleteBundleObject(bundle.id)} className="btn btn-sm btn-outline-danger" style={{ marginLeft: "5px" }}>Delete</button>
                    </td>
                </tr>
                )}

                </tbody>
            </Table>
        </Container>
    )
        ;
}

export default Bundle;
import React, {useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {update_instance_detail} from '../redux/auth/actions';

const Dashboard = () => {

    const dispatch = useDispatch();

    useEffect(() => {
        const queryParams = new URLSearchParams(window.location.search);

        const instanceId = queryParams.get('instanceId');
        const secret = queryParams.get('secret');
        dispatch(update_instance_detail({app_secret: secret, instance_id: instanceId}))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const {app_secret, instance_id} = useSelector((state) => ({
        app_secret: state.AuthReducer.app_secret,
        instance_id: state.AuthReducer.instance_id,
    }));

    console.log(app_secret, "App Secret")
    console.log(instance_id, "Instance ID")

    return (
        <div className="App" style={{textAlign: "center"}}>
            <div className="mt-3">
                Wix frontend dashboard
            </div>

            <div>
                <a href={`/bundles?instanceId=${instance_id}&secret=${app_secret}`}
               className="btn btn-outline-primary mb-3  mt-3">Bundles</a>
            </div>

            <div>
                <a href={`/cross_sell?instanceId=${instance_id}&secret=${app_secret}`}
                   className="btn btn-outline-primary mb-3  mt-3">Cross-sell</a>
            </div>

            <div>
                <a href={`/volume_discounts?instanceId=${instance_id}&secret=${app_secret}`}
                   className="btn btn-outline-primary mb-3  mt-3">Volume Discounts</a>
            </div>
        </div>
    );
}

export default Dashboard;
import {ActionTypes} from "./constants";

const initial_state = {
    instance_id: "",
    app_secret: ""
};

const AuthReducer = (state = initial_state, action) => {

    switch (action.type) {
        case ActionTypes.API_RESPONSE_SUCCESS:
            case ActionTypes.UPDATE_INSTANCE_DETAIL:

              return {
                ...state,
                app_secret: action.payload.data.app_secret,
                instance_id: action.payload.data.instance_id
              }

            default:
               return {...state};
    }

}

export default AuthReducer;
export const ProductMappingActionTypes = {
    CREATE_PRODUCT_MAPPING: "CREATE_PRODUCT_MAPPING",
    UPDATE_PRODUCT_MAPPING: "UPDATE_PRODUCT_MAPPING",
    PRODUCT_MAPPING_LIST: "PRODUCT_MAPPING_LIST",
    GET_PRODUCT_MAPPING: "GET_PRODUCT_MAPPING",
    DELETE_PRODUCT_MAPPING: "DELETE_PRODUCT_MAPPING",

    API_RESPONSE_SUCCESS: 'API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: 'API_RESPONSE_ERROR',

}

import React, {useEffect} from "react";
import {Table, Container} from "reactstrap";
import {useDispatch, useSelector} from 'react-redux';
import { listVolumeDiscounts, deleteVolumeDiscount } from '../../redux/volume_discount/actions';

const VolumeDiscount = () => {
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(window.location.search);
    const instanceId = queryParams.get('instanceId');


    useEffect(() => {
        dispatch(listVolumeDiscounts("", instanceId));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { volume_discounts } = useSelector((state) => ({
        volume_discounts: state.VolumeDiscountReducer.volume_discounts
    }));

    const deleteVolumeDiscountObject = (id) => {
        if (window.confirm("Are you sure?")) {
            dispatch(deleteVolumeDiscount(id, instanceId))
        }
    }

    return (
        <Container className="mt-3">
            <h3 className="mt-3 mb-3">Volume discount</h3>
            <a href={`/volume_discounts/new?instanceId=${instanceId}`} className="btn btn-outline-primary mb-3 float-end">New volume discount</a>
            <Table bordered>
                <thead>
                <tr>
                    <th>
                        Name
                    </th>
                    <th>
                        Title
                    </th>
                    <th>
                        Subtitle
                    </th>
                    <th>
                        Offer type
                    </th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                { volume_discounts.map( volume_discount =>
                    <tr key={volume_discount.id}>
                        <th scope="row">
                            {volume_discount.name}
                        </th>
                        <td>
                            {volume_discount.title}
                        </td>
                        <td>
                            {volume_discount.subtitle}
                        </td>
                        <td>
                            {volume_discount.offer_type}
                        </td>
                        <td>
                            <a href={`/volume_discounts/${volume_discount.id}/edit?instanceId=${instanceId}`} className="btn btn-sm btn-outline-secondary">Edit</a>
                            <button onClick={() => deleteVolumeDiscountObject(volume_discount.id)} className="btn btn-sm btn-outline-danger" style={{ marginLeft: "5px" }}>Delete</button>
                        </td>
                    </tr>
                )}

                </tbody>
            </Table>
        </Container>
    )
        ;
}

export default VolumeDiscount;
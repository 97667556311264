import { all, fork, takeEvery, put, call } from 'redux-saga/effects';
import { select } from 'redux-saga/effects'

import {
    volumeDiscountCreate as volumeDiscountCreateApi,
    volumeDiscountList as volumeDiscountListApi,
    volumeDiscountDelete as volumeDiscountDeleteApi,
    volumeDiscountUpdate as volumeDiscountUpdateApi,
    volumeDiscountGet as volumeDiscountGetApi
} from "../../helpers/api/volume_discount"

import { VolumeDiscountActionTypes } from "./constants";
import {authApiResponseError, authApiResponseSuccess} from "./actions";


function* create({ payload: { data, instance_id } }) {

    const items = yield select();

    try {

        const response = yield call(volumeDiscountCreateApi, {data: data, instance_id: instance_id });
        const volumeDiscount = response.data.volume_discount;

        yield put(authApiResponseSuccess(VolumeDiscountActionTypes.CREATE_VOLUME_DISCOUNT, {volume_discounts: items.VolumeDiscountReducer.volume_discounts, volume_discount: volumeDiscount }));
    } catch (error) {
        yield put(authApiResponseError(VolumeDiscountActionTypes.CREATE_VOLUME_DISCOUNT, error));
    }
}

function* list(action) {

    try {
        const response = yield call(volumeDiscountListApi, {instance_id: action.payload.instance_id});
        const volumeDiscounts = response.data?.volume_discounts;

        yield put(authApiResponseSuccess(VolumeDiscountActionTypes.VOLUME_DISCOUNT_LIST, volumeDiscounts));
    } catch (error) {
        yield put(authApiResponseError(VolumeDiscountActionTypes.VOLUME_DISCOUNT_LIST, error));
    }
}

function* get(action) {

    try {
        const response = yield call(volumeDiscountGetApi, {id: action.payload.data, instance_id: action.payload.instance_id});
        const volumeDiscount = response.data?.volume_discount;

        yield put(authApiResponseSuccess(VolumeDiscountActionTypes.GET_VOLUME_DISCOUNT, volumeDiscount));
    } catch (error) {
        yield put(authApiResponseError(VolumeDiscountActionTypes.GET_VOLUME_DISCOUNT, error));
    }
}

function* update(action) {

    const items = yield select();

    try {
        const response = yield call(volumeDiscountUpdateApi, {id: action.payload.id, data: action.payload.data, instance_id: action.payload.instance_id});
        const volumeDiscount = response.data?.volume_discount;

        yield put(authApiResponseSuccess(VolumeDiscountActionTypes.UPDATE_VOLUME_DISCOUNT, {volume_discounts: items.VolumeDiscountReducer.volume_discounts, volume_discount: volumeDiscount, id: action.payload.data }));
    } catch (error) {
        yield put(authApiResponseError(VolumeDiscountActionTypes.UPDATE_VOLUME_DISCOUNT, error));
    }
}

function* deleteVolumeDiscount(action) {

    const items = yield select();

    try {

        yield call(volumeDiscountDeleteApi, {id: action.payload.data, instance_id: action.payload.instance_id});

        yield put(authApiResponseSuccess(VolumeDiscountActionTypes.DELETE_VOLUME_DISCOUNT, {volume_discounts: items.VolumeDiscountReducer.volume_discounts, id: action.payload.data }));
    } catch (error) {
        yield put(authApiResponseError(VolumeDiscountActionTypes.DELETE_VOLUME_DISCOUNT, error));
    }
}

export function* watchVolumeDiscountCreate() {
    yield takeEvery(VolumeDiscountActionTypes.CREATE_VOLUME_DISCOUNT, create);
}

export function* watchVolumeDiscountList() {
    yield takeEvery(VolumeDiscountActionTypes.VOLUME_DISCOUNT_LIST, list);
}

export function* watchVolumeDiscountDelete() {
    yield takeEvery(VolumeDiscountActionTypes.DELETE_VOLUME_DISCOUNT, deleteVolumeDiscount);
}

export function* watchVolumeDiscountGet() {
    yield takeEvery(VolumeDiscountActionTypes.GET_VOLUME_DISCOUNT, get);
}

export function* watchVolumeDiscountUpdate() {
    yield takeEvery(VolumeDiscountActionTypes.UPDATE_VOLUME_DISCOUNT, update);
}


function* volumeDiscountSaga() {
    yield all([
        fork(watchVolumeDiscountCreate),
        fork(watchVolumeDiscountList),
        fork(watchVolumeDiscountDelete),
        fork(watchVolumeDiscountGet),
        fork(watchVolumeDiscountUpdate)
    ]);
}

export default volumeDiscountSaga;
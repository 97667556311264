import {
    BundleActionTypes
} from "./constants";

export const listBundles = (data, instance_id) => ({
    type: BundleActionTypes.BUNDLE_LIST,
    payload: { data, instance_id },
});

export const listBundleProducts = (instance_id) => ({
    type: BundleActionTypes.LIST_BUNDLE_PRODUCT,
    payload: { instance_id },
});

export const getBundle = (data, instance_id) => ({
    type: BundleActionTypes.GET_BUNDLE,
    payload: { data, instance_id },
});

export const createBundle = (data, instance_id) => ({
    type: BundleActionTypes.CREATE_BUNDLE,
    payload: { data, instance_id },
});

export const updateBundle = (data, id, instance_id) => ({
    type: BundleActionTypes.UPDATE_BUNDLE,
    payload: { data, id, instance_id },
});

export const deleteBundle = (data, instance_id) => ({
    type: BundleActionTypes.DELETE_BUNDLE,
    payload: { data, instance_id },
});

export const authApiResponseSuccess = (actionType, data) => ({
    type: BundleActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});

export const authApiResponseError = (actionType, error) => ({
    type: BundleActionTypes.API_RESPONSE_ERROR,
    payload: { actionType, error },
});
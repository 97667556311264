import { all, fork, takeEvery, put, call } from 'redux-saga/effects';
import { select } from 'redux-saga/effects'

import {
    bundleCreate as bundleCreateApi,
    bundleList as bundleListApi,
    bundleDelete as bundleDeleteApi,
    bundleUpdate as bundleUpdateApi,
    bundleGet as bundleGetApi,
    productList as productListApi
} from "../../helpers/api/bundle"

import { BundleActionTypes } from "./constants";
import {authApiResponseError, authApiResponseSuccess} from "./actions";


function* create({ payload: { data, instance_id } }) {

    const items = yield select();

    try {
        const response = yield call(bundleCreateApi, {data: data, instance_id: instance_id });
        const bundle = response.data.bundle;

        yield put(authApiResponseSuccess(BundleActionTypes.CREATE_BUNDLE, {bundles: items.BundleReducer.bundles, bundle: bundle }));
    } catch (error) {
        yield put(authApiResponseError(BundleActionTypes.CREATE_BUNDLE, error));
    }
}

function* list(action) {

    try {
        const response = yield call(bundleListApi, {instance_id: action.payload.instance_id});
        const bundles = response.data?.bundles;

        yield put(authApiResponseSuccess(BundleActionTypes.BUNDLE_LIST, bundles));
    } catch (error) {
        yield put(authApiResponseError(BundleActionTypes.BUNDLE_LIST, error));
    }
}

function* productsList(action) {

    try {
        const response = yield call(productListApi, {instance_id: action.payload.instance_id});
        const products = response.data?.products;

        yield put(authApiResponseSuccess(BundleActionTypes.LIST_BUNDLE_PRODUCT, products));
    } catch (error) {
        yield put(authApiResponseError(BundleActionTypes.LIST_BUNDLE_PRODUCT, error));
    }
}

function* get(action) {

    try {
        const response = yield call(bundleGetApi, {id: action.payload.data, instance_id: action.payload.instance_id});
        const bundle = response.data?.bundle;

        yield put(authApiResponseSuccess(BundleActionTypes.GET_BUNDLE, bundle));
    } catch (error) {
        yield put(authApiResponseError(BundleActionTypes.GET_BUNDLE, error));
    }
}

function* update(action) {

    const items = yield select();

    try {
        const response = yield call(bundleUpdateApi, {id: action.payload.id, data: action.payload.data, instance_id: action.payload.instance_id});
        const bundle = response.data?.bundle;

        yield put(authApiResponseSuccess(BundleActionTypes.UPDATE_BUNDLE, {bundles: items.BundleReducer.bundles, bundle: bundle, id: action.payload.data }));
    } catch (error) {
        yield put(authApiResponseError(BundleActionTypes.UPDATE_BUNDLE, error));
    }
}

function* deleteBundle(action) {

    const items = yield select();

    try {

        yield call(bundleDeleteApi, {id: action.payload.data, instance_id: action.payload.instance_id});

        yield put(authApiResponseSuccess(BundleActionTypes.DELETE_BUNDLE, {bundles: items.BundleReducer.bundles, id: action.payload.data }));
    } catch (error) {
        yield put(authApiResponseError(BundleActionTypes.DELETE_BUNDLE, error));
    }
}

export function* watchBundleCreate() {
    yield takeEvery(BundleActionTypes.CREATE_BUNDLE, create);
}

export function* watchBundleList() {
    yield takeEvery(BundleActionTypes.BUNDLE_LIST, list);
}

export function* watchBundleDelete() {
    yield takeEvery(BundleActionTypes.DELETE_BUNDLE, deleteBundle);
}

export function* watchBundleGet() {
    yield takeEvery(BundleActionTypes.GET_BUNDLE, get);
}

export function* watchBundleUpdate() {
    yield takeEvery(BundleActionTypes.UPDATE_BUNDLE, update);
}

export function* watchBundleProducts() {
    yield takeEvery(BundleActionTypes.LIST_BUNDLE_PRODUCT, productsList);
}

function* bundleSaga() {
    yield all([
        fork(watchBundleCreate),
        fork(watchBundleList),
        fork(watchBundleDelete),
        fork(watchBundleGet),
        fork(watchBundleUpdate),
        fork(watchBundleProducts)
    ]);
}

export default bundleSaga;
export const BundleActionTypes = {
    CREATE_BUNDLE: "CREATE_BUNDLE",
    UPDATE_BUNDLE: "UPDATE_BUNDLE",
    BUNDLE_LIST: "BUNDLE_LIST",
    LIST_BUNDLE_PRODUCT: "LIST_BUNDLE_PRODUCT",
    GET_BUNDLE: "GET_BUNDLE",
    DELETE_BUNDLE: "DELETE_BUNDLE",

    API_RESPONSE_SUCCESS: 'API_RESPONSE_SUCCESS',
    API_RESPONSE_ERROR: 'API_RESPONSE_ERROR',

}

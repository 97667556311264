import React from "react";

const Home = () => {

    return (
        <div className="App">
            Wix frontend
        </div>
    );
}

export  default Home;
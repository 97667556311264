import React, {useState, useEffect} from "react";
import {useDispatch, useSelector} from 'react-redux';
import {
    Button,
    Form,
    FormGroup,
    Label,
    Input,
    Container, ModalHeader, ModalBody, ModalFooter, Modal
} from 'reactstrap';
import {createVolumeDiscount, listVolumeDiscounts} from '../../redux/volume_discount/actions';
import {useNavigate} from "react-router-dom";
import {listBundleProducts} from "../../redux/bundle/actions";

const InitialState = {
    name: "",
    title: "",
    subtitle: "",
    add_variant_text: "",
    offer_type: "single",
    product_id: "",
    discount_tiers: ""
}

const VolumeDiscountCreate = () => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const [volumeDiscountObject, setVolumeDiscountObject] = useState(InitialState);
    const queryParams = new URLSearchParams(window.location.search);
    const instanceId = queryParams.get('instanceId');
    const [modal, setModal] = useState(false);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [discountTierLength, setDiscountTierLength] = useState(0)

    const toggle = (value) => {
        dispatch(listBundleProducts(instanceId))
        setModal(!modal);
    }


    useEffect(() => {
        dispatch(listVolumeDiscounts("", instanceId));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const handleSubmit = (e) => {
        e.preventDefault();

        const requestBody = {volume_discount: volumeDiscountObject}

        dispatch(createVolumeDiscount(requestBody, instanceId))

        history("/volume_discounts?instanceId=" + instanceId)

    };

    const {products} = useSelector((state) => ({
        products: state.BundleReducer.bundle_products
    }));

    const handleRemoveSelectedProducts = (product) => {
        let selectedProductList = ""

        selectedProductList = selectedProducts
        setSelectedProducts(selectedProductList.filter(p => p.id !== product.id))

        setVolumeDiscountObject({
            ...volumeDiscountObject, product_id: product.id
        })

    }

    const handleSetSearchedProducts = (product) => {

        let selectedProductList = []
        selectedProductList.push(product)

        setSelectedProducts(selectedProductList)

        setVolumeDiscountObject({
            ...volumeDiscountObject, product_id: product.id
        })

        setModal(false)
        setFilteredProducts([])


    }

    const handleSetProducts = (value) => {
        const filteredProductsList = products.filter(product => product.name.includes(value))

        let productsList = []
        for (let i = 0; i < filteredProductsList.length; i++) {

            if (! (volumeDiscountObject.product_id === filteredProductsList[i].id)) {
                productsList.push(filteredProductsList[i])
            }

        }
        setFilteredProducts(productsList)

    }

    const handleSetTierLength = () => {
        setDiscountTierLength((discountTierLength + 1))
    }

    const handleRemoveTier = (key) => {
        const newItems = {...volumeDiscountObject.discount_tiers};
        delete newItems[key];
        setVolumeDiscountObject({
            ...volumeDiscountObject, discount_tiers: newItems
        })
        setDiscountTierLength((discountTierLength - 1))
    }

    const getDiscountTierElementValue = (key, index) => {
        if(volumeDiscountObject.discount_tiers !== ""){
            if(volumeDiscountObject.discount_tiers[index]){
                return volumeDiscountObject.discount_tiers[index][key]
            }
        }
    }

    const handleBundleTierChangeAmount = (value, index) => {
        let discount_tiers = (volumeDiscountObject.discount_tiers || {})
        let discountElements = {}

        if(volumeDiscountObject.discount_tiers[index]){
            discountElements = volumeDiscountObject.discount_tiers[index]
        }
        discountElements["amount"] = value
        discount_tiers[index] = discountElements

        setVolumeDiscountObject({
            ...volumeDiscountObject, discount_tiers: discount_tiers
        })
    }

    const handleBundleTierChangeCustomTitle = (value, index) => {
        let discount_tiers = (volumeDiscountObject.discount_tiers || {})
        let discountElements = {}

        if(volumeDiscountObject.discount_tiers[index]){
            discountElements = volumeDiscountObject.discount_tiers[index]
        }
        discountElements["custom_title"] = value
        discount_tiers[index] = discountElements

        setVolumeDiscountObject({
            ...volumeDiscountObject, discount_tiers: discount_tiers
        })
    }

    const handleBundleTierChangeQuantity = (value, index) => {
        let discount_tiers = (volumeDiscountObject.discount_tiers || {})
        let discountElements = {}

        if(volumeDiscountObject.discount_tiers[index]){
            discountElements = volumeDiscountObject.discount_tiers[index]
        }
        discountElements["quantity"] = value
        discount_tiers[index] = discountElements

        setVolumeDiscountObject({
            ...volumeDiscountObject, discount_tiers: discount_tiers
        })
    }

    const handleBundleTierChangeQuantitySteps = (value, index) => {
        let discount_tiers = (volumeDiscountObject.discount_tiers || {})
        let discountElements = {}

        if(volumeDiscountObject.discount_tiers[index]){
            discountElements = volumeDiscountObject.discount_tiers[index]
        }
        discountElements["quantity_steps"] = value
        discount_tiers[index] = discountElements

        setVolumeDiscountObject({
            ...volumeDiscountObject, discount_tiers: discount_tiers
        })
    }

    const getDiscountTiers = () => {
        let discountTiers = []

        for (let i = 0; i < discountTierLength; i++) {
            discountTiers.push(<div key={i} className="mt-3 row">

                <div className="col-md-2">
                    <Label>Quantity</Label>
                    <Input type="number"
                           name="quantity"
                           value={getDiscountTierElementValue("quantity", i)}
                           onChange={event => { handleBundleTierChangeQuantity(event.target.value, i) }}
                    />
                </div>
                <div className="col-md-2">
                    <Label>Quantity steps</Label>
                    <Input type="number"
                           name="quantity"
                           value={getDiscountTierElementValue("quantity_steps", i)}
                           onChange={event => { handleBundleTierChangeQuantitySteps(event.target.value, i) }}
                    />
                </div>
                <div className="col-md-2">
                    <Label>Discount</Label>
                    <Input type="text"
                           name="amount"
                           value={getDiscountTierElementValue("amount", i)}
                           onChange={event => { handleBundleTierChangeAmount(event.target.value, i) }}
                    />
                </div>
                <div className="col-md-3">
                    <Label>Custom title</Label>
                    <Input type="text"
                           name="amount"
                           value={getDiscountTierElementValue("custom_title", i)}
                           onChange={event => { handleBundleTierChangeCustomTitle(event.target.value, i) }}
                    />
                </div>
                <div className="col-md-2">
                    <button type="button" className="btn btn-sm btn-outline-danger mt-4" onClick={event => { handleRemoveTier(i) }} >Remove</button>
                </div>


            </div>)
        }

        return discountTiers
    }


    return (
        <Container className="mt-3">
            <h3 className="text-center mb-3">New Volume Discount</h3>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label for="volumeDiscountName">Name</Label>
                    <Input type="text"
                           name="name"
                           id="volumeDiscountName"
                           value={volumeDiscountObject.name}
                           onChange={event => setVolumeDiscountObject({
                               ...volumeDiscountObject, name: event.target.value
                           })}
                           placeholder="VolumeDiscount name"/>
                </FormGroup>

                <FormGroup>
                    <Label for="volumeDiscountTitle">Title</Label>
                    <Input type="text"
                           name="title"
                           id="volumeDiscountTitle"
                           value={volumeDiscountObject.title}
                           onChange={event => setVolumeDiscountObject({
                               ...volumeDiscountObject, title: event.target.value
                           })}
                           placeholder="VolumeDiscount title"/>
                </FormGroup>

                <FormGroup>
                    <Label for="volumeDiscountSubTitle">Subtitle</Label>
                    <Input type="text"
                           name="subtitle"
                           id="volumeDiscountSubTitle"
                           value={volumeDiscountObject.subtitle}
                           onChange={event => setVolumeDiscountObject({
                               ...volumeDiscountObject, subtitle: event.target.value
                           })}
                           placeholder="VolumeDiscount subtitle"/>
                </FormGroup>

                <FormGroup>
                    <Label for="volumeDiscountAddVariantText">Add Variant Text</Label>
                    <Input type="text"
                           name="subtitle"
                           id="volumeDiscountAddVariantText"
                           value={volumeDiscountObject.add_variant_text}
                           onChange={event => setVolumeDiscountObject({
                               ...volumeDiscountObject, add_variant_text: event.target.value
                           })}
                           placeholder="VolumeDiscount Add Variant Text"/>
                </FormGroup>

                <FormGroup>
                    <Label for="offerType">Offer type</Label>
                    <Input type="select"
                           name="select"
                           value={volumeDiscountObject.offer_type}
                           onChange={event => setVolumeDiscountObject({
                               ...volumeDiscountObject, offer_type: event.target.value
                           })}
                           id="offerType">
                        <option value="single">Discount on single product</option>
                        <option value="group">Discount on single product from a group</option>
                    </Input>
                </FormGroup>


                <FormGroup>
                    <button type="button" className="btn btn-outline-primary" onClick={event => { toggle("source_product") }}>
                        Select Product
                    </button>

                    <div className="mt-3">
                        {selectedProducts.map(product => <div className="mt-2" key={product.id}><span><img
                            src={product.wix_image_id} style={{width: "100px"}} alt=""/>{product.name}</span>
                            <button className="btn btn-sm btn-outline-danger" type="button"
                                    onClick={event => handleRemoveSelectedProducts(product, "source_product")}
                                    style={{marginLeft: "50px"}}>Remove
                            </button>
                        </div>)}
                    </div>
                </FormGroup>

                <FormGroup>
                    <button type="button" className="btn  btn-outline-success" onClick={handleSetTierLength}>Add
                        Tier
                    </button>

                    <div className="mt-3">
                        {getDiscountTiers()}
                    </div>


                </FormGroup>



                <Button>Create</Button>
            </Form>


            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Select Products</ModalHeader>
                <ModalBody>

                    <FormGroup>
                        <Label>Search</Label>
                        <input type="text"
                               onChange={event => handleSetProducts(event.target.value)}
                               placeholder="Search Phrase, e.g. Shirts for men"
                               className="form-control"/>
                    </FormGroup>

                    <div className="mt-3">
                        {
                            filteredProducts.map(product => <div className="mt-2" style={{cursor: "pointer"}}
                                                                 key={product.id}
                                                                 onClick={event => handleSetSearchedProducts(product)}><img
                                src={product.wix_image_id} style={{width: "100px"}} alt=""/>{product.name}</div>)
                        }
                    </div>

                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>



        </Container>
    );
}

export default VolumeDiscountCreate;
import {
    ActionTypes
} from "./constants";

export const update_instance_detail = (data) => ({
    type: ActionTypes.UPDATE_INSTANCE_DETAIL,
    payload: { data },
});

export const authApiResponseSuccess = (actionType, data) => ({
    type: ActionTypes.API_RESPONSE_SUCCESS,
    payload: { actionType, data },
});


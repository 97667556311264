import React, {useEffect, useState} from "react";
import {Button, Form, FormGroup, Label, Input, Container, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import {useDispatch, useSelector} from 'react-redux';
import {updateBundle, getBundle, listBundles, listBundleProducts} from '../../redux/bundle/actions';
import {useParams, useNavigate} from "react-router-dom";

const BundleEdit = () => {

    const dispatch = useDispatch();
    const params = useParams();
    const history = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    const [modal, setModal] = useState(false);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [selectedProducts, setSelectedProducts] = useState([]);
    const [showTierButton, setShowTierButton] = useState(false)
    const [discountTierLength, setDiscountTierLength] = useState(0)

    const instanceId = queryParams.get('instanceId');
    const [bundleObject, setBundleObject] = useState('');

    const toggle = () => {
        dispatch(listBundleProducts(instanceId))
        setModal(!modal);
    }

    useEffect(() => {
        dispatch(listBundles("", instanceId));
        dispatch(getBundle(params.id, instanceId));
        dispatch(listBundleProducts(instanceId))

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const {bundle, products} = useSelector((state) => ({
        bundle: state.BundleReducer.bundle,
        products: state.BundleReducer.bundle_products
    }));

    useEffect(() => {
        setBundleObject(bundle)

        if (bundleObject.bundle_type === "tiers") {
            setShowTierButton(true)

            let last = (Object.keys(bundleObject.discount_tiers)[Object.keys(bundleObject.discount_tiers).length - 1])

            setDiscountTierLength(parseInt(last) + 1)
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bundle])

    useEffect(() => {
        let selectedProductList = []

        for (let i = 0; i < products.length; i++) {
            if (bundle.products_ids) {
                if (bundle.products_ids.includes(`${products[i].id}`)) {
                    selectedProductList.push(products[i])
                }
            }
        }

        setSelectedProducts(selectedProductList)
        // eslint-disable-next-line
    }, [products])

    const handleSubmit = (e) => {
        e.preventDefault();

        const requestBody = {bundle: bundleObject}

        dispatch(updateBundle(requestBody, params.id, instanceId))

        history("/bundles?instanceId=" + instanceId)

    };

    const handleSetProducts = (value) => {
        const filteredProductsList = products.filter(product => product.name.includes(value))

        let productsList = []
        for (let i = 0; i < filteredProductsList.length; i++) {
            if (!bundleObject.products_ids.includes(`${filteredProductsList[i].id}`)) {
                productsList.push(filteredProductsList[i])
            }
        }

        setFilteredProducts(productsList)
    }

    const handleSetSearchedProducts = (product) => {
        let products_ids = bundleObject.products_ids
        products_ids.push(product.id)

        let selectedProductList = selectedProducts
        selectedProductList.push(product)

        setSelectedProducts(selectedProductList)

        setBundleObject({
            ...bundleObject, products_ids: products_ids
        })
        setModal(false)
        setFilteredProducts([])
    }

    const handleRemoveSelectedProducts = (product) => {
        let selectedProductList = selectedProducts
        setSelectedProducts(selectedProductList.filter(p => p.id !== product.id))

        let products_ids = bundleObject.products_ids
        products_ids = products_ids.filter(p => p.toString() !== product.id.toString())

        setBundleObject({
            ...bundleObject, products_ids: products_ids
        })
    }

    const handleSetBundleType = (value) => {

        if (value === "tiers") {
            setShowTierButton(true)
        } else {

            setBundleObject({
                ...bundleObject, discount_tiers: {}
            })

            setDiscountTierLength(0)

            setShowTierButton(false)
        }

        setBundleObject({
            ...bundleObject, bundle_type: value
        })
    }

    const handleSetTierLength = () => {
        setDiscountTierLength((discountTierLength + 1))
    }

    const handleBundleTierChangeQuantity = (value, index) => {
        let discount_tiers = (bundleObject.discount_tiers || {})
        let discountElements = {}

        if (bundleObject.discount_tiers[index]) {
            discountElements = bundleObject.discount_tiers[index]
        }
        discountElements["quantity"] = value
        discount_tiers[index] = discountElements

        setBundleObject({
            ...bundleObject, discount_tiers: discount_tiers
        })
    }

    const handleBundleTierChangeAmount = (value, index) => {
        let discount_tiers = (bundleObject.discount_tiers || {})
        let discountElements = {}

        if (bundleObject.discount_tiers[index]) {
            discountElements = bundleObject.discount_tiers[index]
        }
        discountElements["amount"] = value
        discount_tiers[index] = discountElements

        setBundleObject({
            ...bundleObject, discount_tiers: discount_tiers
        })
    }

    const handleBundleTierChangeType = (value, index) => {
        let discount_tiers = (bundleObject.discount_tiers || {})
        let discountElements = {}

        if (bundleObject.discount_tiers[index]) {
            discountElements = bundleObject.discount_tiers[index]
        }
        discountElements["type"] = value
        discount_tiers[index] = discountElements

        setBundleObject({
            ...bundleObject, discount_tiers: discount_tiers
        })
    }

    const getDiscountTierElementValue = (key, index) => {
        if (bundleObject.discount_tiers !== "") {
            if (bundleObject.discount_tiers[index]) {
                return bundleObject.discount_tiers[index][key]
            }
        }
    }


    const getDiscountTiers = () => {
        let discountTiers = []

        for (let i = 0; i < discountTierLength; i++) {
            discountTiers.push(<div key={i} className="mt-3 row">

                <div className="col-md-4">
                    <Label>Quantity</Label>
                    <Input type="number"
                           name="quantity"
                           value={getDiscountTierElementValue("quantity", i)}
                           onChange={event => {
                               handleBundleTierChangeQuantity(event.target.value, i)
                           }}
                    />
                </div>
                <div className="col-md-4">
                    <Label>Amount</Label>
                    <Input type="text"
                           name="amount"
                           value={getDiscountTierElementValue("amount", i)}
                           onChange={event => {
                               handleBundleTierChangeAmount(event.target.value, i)
                           }}
                    />
                </div>
                <div className="col-md-2">
                    <Label>Type</Label>
                    <Input type="select"
                           name="type"
                           value={getDiscountTierElementValue("type", i)}
                           onChange={event => {
                               handleBundleTierChangeType(event.target.value, i)
                           }}
                    >
                        <option value="percentage">Percentage</option>
                        <option value="fixed">Fixed</option>
                    </Input>
                </div>
                <div className="col-md-2">
                    <button type="button" className="btn btn-sm btn-outline-danger mt-4" onClick={event => { handleRemoveTier(i) }} >Remove</button>
                </div>


            </div>)
        }

        return discountTiers
    }

    const handleRemoveTier = (key) => {
        const newItems = {...bundleObject.discount_tiers};
        delete newItems[key];
        setBundleObject({
            ...bundleObject, discount_tiers: newItems
        })
        setDiscountTierLength((discountTierLength - 1))
    }

    return (
        <Container className="mt-3">
            <h3 className="text-center mb-3">Edit Bundle</h3>
            <Form onSubmit={handleSubmit}>
                <FormGroup>
                    <Label for="bundleName">Name</Label>
                    <Input type="text"
                           name="name"
                           id="bundleName"
                           value={bundleObject.name}
                           onChange={event => setBundleObject({
                               ...bundleObject, name: event.target.value
                           })}
                           placeholder="Bundle name"/>
                </FormGroup>
                <FormGroup>
                    <Label for="bundleType">Bundle type</Label>
                    <Input type="select"
                           name="select"
                           value={bundleObject.bundle_type}
                           onChange={event => {
                               handleSetBundleType(event.target.value)
                           }}
                           id="bundleType">
                        <option value="percentage">Percentage</option>
                        <option value="fixed">Fixed</option>
                        <option value="tiers">Tiers</option>
                    </Input>
                </FormGroup>

                { ! showTierButton ?
                <FormGroup>
                    <Label for="bundleAmount">Amount</Label>
                    <Input type="text"
                           name="amount"
                           id="bundleAmount"
                           value={bundleObject.amount}
                           onChange={event => setBundleObject({
                               ...bundleObject, amount: event.target.value
                           })}
                           placeholder="Bundle amount"/>
                </FormGroup> : "" }

                <FormGroup>
                    <button type="button" className="btn btn-outline-primary" onClick={toggle}>
                        Select Products
                    </button>

                    <div className="mt-3">
                        {selectedProducts.map(product => <div className="mt-2" key={product.id}><span><img
                            src={product.wix_image_id} style={{width: "100px"}} alt=""/>{product.name}</span>
                            <button className="btn btn-sm btn-outline-danger" type="button"
                                    onClick={event => handleRemoveSelectedProducts(product)}
                                    style={{marginLeft: "50px"}}>Remove
                            </button>
                        </div>)}
                    </div>
                </FormGroup>

                <FormGroup>
                    {showTierButton ?
                        <button type="button" className="btn  btn-outline-success" onClick={handleSetTierLength}>Add
                            Tier</button> : ""}

                    <div className="mt-3">
                        {getDiscountTiers()}
                    </div>


                </FormGroup>

                <Button>Update</Button>
            </Form>

            <Modal isOpen={modal} toggle={toggle}>
                <ModalHeader toggle={toggle}>Select Products</ModalHeader>
                <ModalBody>

                    <FormGroup>
                        <Label>Search</Label>
                        <input type="text"
                               onChange={event => handleSetProducts(event.target.value)}
                               placeholder="Search Phrase, e.g. Shirts for men"
                               className="form-control"/>
                    </FormGroup>

                    <div className="mt-3">
                        {filteredProducts.map(product => <div className="mt-2" style={{cursor: "pointer"}}
                                                              key={product.id}
                                                              onClick={event => handleSetSearchedProducts(product)}><img
                            src={product.wix_image_id} style={{width: "100px"}} alt=""/>{product.name}</div>)}
                    </div>

                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={toggle}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>

        </Container>
    );
}

export default BundleEdit;
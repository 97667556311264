import {BundleActionTypes} from "./constants";

const initial_state = {
    bundle_products: [],
    bundles: [],
    bundle: ""
};

const BundleReducer = (state = initial_state, action) => {

    switch (action.type) {
        case BundleActionTypes.API_RESPONSE_SUCCESS:
            switch (action.payload.actionType) {

                case BundleActionTypes.BUNDLE_LIST: {
                    return {
                        ...state,
                        bundles: action.payload.data,
                        loading: false,
                    };
                }
                case BundleActionTypes.GET_BUNDLE: {
                    return {
                        ...state,
                        bundle: action.payload.data,
                        loading: false,
                    };
                }
                case BundleActionTypes.CREATE_BUNDLE: {
                    let bundles = action.payload.bundles
                    bundles.push(action.payload.bundle)
                    return {
                        ...state,
                        bundles: bundles,
                        loading: false
                    };
                }
                case BundleActionTypes.LIST_BUNDLE_PRODUCT: {
                    return {
                        ...state,
                        bundle_products: action.payload.data,
                        loading: false
                    };
                }
                case BundleActionTypes.UPDATE_BUNDLE: {

                    let bundles = action.payload.data.bundles.filter(bundle => bundle.id !== action.payload.data.id)
                    bundles.push(action.payload.data.bundle)

                    return {
                        ...state,
                        bundles: bundles,
                        loading: false
                    };
                }
                case BundleActionTypes.DELETE_BUNDLE: {
                    return {
                        ...state,
                        bundles: action.payload.data.bundles.filter(bundle => bundle.id !== action.payload.data.id),
                        loading: false
                    };
                }
                default:
                    return {...state};
            }


        case BundleActionTypes.API_RESPONSE_ERROR:
            switch (action.payload.actionType) {
                case BundleActionTypes.BUNDLE_LIST: {
                    return {
                        ...state,
                        bundles: [],
                        loading: false,
                    };
                }
                case BundleActionTypes.GET_BUNDLE: {
                    return {
                        ...state,
                        bundle: "",
                        loading: false,
                    };
                }
                case BundleActionTypes.CREATE_BUNDLE: {
                    return {
                        ...state,
                        bundle: action.payload.data,
                        loading: false
                    };
                }
                default:
                    return {...state};
            }
        default:
            return {...state};
    }
}

export default BundleReducer;
import React, {useEffect} from "react";
import {Table, Container} from "reactstrap";
import {useDispatch, useSelector} from 'react-redux';
import { listProductMappings, deleteProductMapping } from '../../redux/product_mapping/actions';

const ProductMapping = () => {
    const dispatch = useDispatch();
    const queryParams = new URLSearchParams(window.location.search);
    const instanceId = queryParams.get('instanceId');


    useEffect(() => {
        dispatch(listProductMappings("", instanceId));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { product_mappings } = useSelector((state) => ({
        product_mappings: state.ProductMappingReducer.product_mappings
    }));

    const deleteProductMappingObject = (id) => {
        if (window.confirm("Are you sure?")) {
            dispatch(deleteProductMapping(id, instanceId))
        }
    }

    return (
        <Container className="mt-3">
            <h3 className="mt-3 mb-3">Cross sell offers</h3>
            <a href={`/cross_sell/new?instanceId=${instanceId}`} className="btn btn-outline-primary mb-3 float-end">New cross sell offer</a>
            <Table bordered>
                <thead>
                <tr>
                    <th>
                        Name
                    </th>
                    <th>
                        On Product Page
                    </th>
                    <th>
                        On Cart Page
                    </th>
                    <th>Actions</th>
                </tr>
                </thead>
                <tbody>
                { product_mappings.map( productMapping =>
                    <tr key={productMapping.id}>
                        <th scope="row">
                            {productMapping.name}
                        </th>
                        <td>
                            {productMapping.on_product_page ? 'Yes' : 'No'}
                        </td>
                        <td>
                            {productMapping.on_cart_page ? 'Yes' : 'No'}
                        </td>
                        <td>
                            <a href={`/cross_sell/${productMapping.id}/edit?instanceId=${instanceId}`} className="btn btn-sm btn-outline-secondary">Edit</a>
                            <button onClick={() => deleteProductMappingObject(productMapping.id)} className="btn btn-sm btn-outline-danger" style={{ marginLeft: "5px" }}>Delete</button>
                        </td>
                    </tr>
                )}

                </tbody>
            </Table>
        </Container>
    )
        ;
}

export default ProductMapping;
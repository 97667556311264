import axios from 'axios';
import config from '../../config';

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL = config.API_URL;

class APICore {
    create = (url, data, headers) => {
        return axios.post(url, data, headers);
    };

    update = (url, data, headers) => {
        return axios.put(url, data, headers);
    };

    get = (url, headers) => {
        return axios.get(url, headers);
    }

    delete = (url, headers) => {
        return axios.delete(url, headers);
    };
}
export {APICore};